.contact-title {
    display: relative;
}

.contact-top-area {
    margin-top: -20px;
    margin-bottom: 30px;
    display: relative;
    align-items: center;
    padding: 30px;
    position: relative;
}

.canteen-dropdown {
    padding: 10px;
    font-size: 16px;
}

.contact-add-button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
}

.contact-add-button:hover {
    background-color: #ff6000;
}

.contact-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.contact-card {
    background-color: rgba(0, 8, 255, 0.266);
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 25px;
    width: calc(33.333% - 20px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.contact-card:hover {
    transform: scale(1.05);
    transition: ease 0.3s;
}

.contact-card-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.contact-card-actions button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 18px;
}

.contact-card-actions button:hover {
    color: #007BFF;
}

.contact-popup-actions {
    display: flex;
    justify-content: space-around;
    margin-top: 25px;
}

.contact-popup-actions button {
    background-color: #ccc;
    border: none;
    cursor: pointer;
    font-size: 12px;
}

.contact-popup-actions button:hover {
    color: aqua;
}

/* CSS for the contact popup overlay */
.contact-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure the popup appears above other content */
}

/* CSS for the contact popup container */
.contact-popup {
    background: white;
    padding: 40px;
    border-radius: 8px;
    width: 380px;
    height: 400px;
    max-width: 600px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    overflow-y: auto; /* Make the content inside the popup scrollable */
}

/* Additional CSS for form inside the popup */
.contact-popup form {
    display: flex;
    flex-direction: column;
}

.contact-popup label {
    margin-top: 15px;
}

.contact-popup input[type="text"],
.contact-popup input[type="email"],
.contact-popup textarea {
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 8px;
    font-family: 'Poppins-lights', sans-serif;
    border: 1px solid #ccc;
    border-radius: 10px;
}

.contact-popup textarea {
    resize: vertical;
}
