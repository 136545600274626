.admins-container {
  padding: 20px;
}

.admin-top-area {
  margin-top: -20px;
  margin-bottom: 30px;
  display: relative;
  align-items: center;
  padding: 30px;
  position: relative;
}

.admins-cards {
  gap: 20px;
}

.admin-card {
  display: flex;
  background: #00ff004a;
  border: 1px solid #ddd;
  padding: 20px;
  margin-top: 20px;
  border-radius: 25px;
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.admin-card h4 {
  margin: 0 0 10px;
}

.admin-card-details{
  width: 80%
}

.admin-card-actions {
  display: flex;
  padding: 4px;
  justify-content: space-between;
  float: right;
  width: 20%;
}

/* CSS for the add popup overlay */
.admin-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure the popup appears above other content */
}

/* CSS for the add popup container */
.admin-popup {
  background: white;
  padding: 40px;
  border-radius: 8px;
  width: 60%;
  height: fit-content;
  max-width: 600px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  overflow-y: auto; /* Make the content inside the popup scrollable */
}

/* Additional CSS for form inside the popup */
.admin-popup form {
  display: flex;
  flex-direction: column;
}

.admin-popup label {
  margin-top: 10px;
}

.admin-popup input[type="text"]{
  margin-top: 5px;
  margin-bottom: 15px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}
