.orders-container {
  display: flex;
  flex-direction: column;
}

.orders-content {
  display: flex;
  justify-content: space-between;
}

.orders-table-container {
  width: 60%;
}

.order-details-container {
  width: 35%;
  border-left: 1px solid #ddd;
  padding-left: 20px;
}
/* 
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

table th, table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

table th {
  background-color: #f2f2f2;
} */

.even-row {
  background-color: hsl(0, 0%, 85%);
}

.odd-row {
  background-color: #fff;
}

table tr:hover {
  background-color: #00344b2e;
}

button {
  background-color: #007bff;
  color: black;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

/* Pagination styles */
.pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
}

.pagination li {
  margin: 0 5px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.pagination li.active {
  background-color: #007bff;
  color: #fff;
  border-radius: 50px;
}

.pagination li:hover:not(.active) {
  background-color: #0000002a;
  border-radius: 50px;
}

.pagination a {
  display: block;
  padding: 8px 16px;
  text-decoration: none;
  color: #333;
}

.pagination a:hover {
  color: #ff6000;
}


.orders-top-area{
    margin-top: -50px;
    display: relative;
    align-items: center;
    padding: 30px;
    position: relative;
  background-color: rgba(0, 204, 255, 0.229);
  border-radius: 15px;
}

.search-button {
  margin-left: 10px;
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.search-button:hover {
  background-color: #0056b3;
}