/* Overall container style */
.daily-report-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
}

/* Heading style */
.daily-report-container h1 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
}

/* Date picker style */
.daily-report-container input[type="date"] {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-right: 10px;
    width: 200px;
}

/* Button styles */
.daily-report-container button {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 10px;
    transition: background-color 0.3s, color 0.3s;
}

.daily-report-container button:disabled {
    background-color: #ddd;
    color: #aaa;
    cursor: not-allowed;
}

.daily-report-container button:not(:disabled) {
    background-color: #007bff;
    color: white;
}

.daily-report-container button:not(:disabled):hover {
    background-color: #0056b3;
}

/* Error message style */
.daily-report-container p {
    color: red;
    font-size: 16px;
    margin-top: 10px;
}
