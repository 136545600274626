.stocks-container {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    width: 100%;
    justify-content: center;
    padding: 40px 10px;
}

.stock-card {
    border: 1px solid #ddd;
    border-radius: 25px;
    padding: 15px;
    width: 300px;
    text-align: center;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease;
}

.stock-card:hover {
    transform: scale(1.05);
}

.stock-card img {
    width: 280px;
    height: 120px;
    object-fit: cover;
    border-radius: 8px;
}

.stock-card h3 {
    margin: 10px 0;
    font-size: 1.4em;
}

.stock-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
}

.stock-controls button {
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 5px 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.stock-controls button:hover {
    background-color: #e0e0e0;
}

.stock-controls .quantity-btn {
    font-weight: bold;
    color: #333;
}

.stock-controls .stock-quantity {
    font-size: 1.5em;
    font-weight: bold;
    cursor: pointer;
    border-bottom: 1px dashed #333;
    color: orangered;
    /* background-color: rgb(197, 255, 255);
    border-radius: 25px;
    padding: 10px; */
    margin: 0 4px;
}

/* Modal Styles */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 300px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.modal-content input {
    width: 100%;
    padding: 10px;
    margin: 15px 0;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.modal-actions {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.modal-actions button {
    flex: 1;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.modal-actions button:first-child {
    background-color: #4CAF50;
    color: white;
}

.modal-actions button:last-child {
    background-color: #f44336;
    color: white;
}