/* SearchBar.css */

.search-containerr {
  position: relative;
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
}

.search-inputt {
  width: 100%;
  max-width: 600px;
  padding: 10px 15px;
  font-size: 16px;
  border: 2px solid #ccc;
  border-radius: 20px;
  outline: none;
  transition: border-color 0.3s ease;
}

.search-inputt:focus {
  border-color: #007bff;
}

/* If you want to include a search button */
/* .search-button {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 20px;
    padding: 8px 16px;
    cursor: pointer;
    outline: none;
    transition: background-color 0.3s ease;
  }
  
  .search-button:hover {
    background-color: #0056b3;
  } */