.analytics-container {
    padding: 20px;
  }
  
  .analytics-dropdown-container {
    position: relative;
    margin-bottom: 18px;
    padding: 5px;
  }

  .analytics-dropdown-container{
    display: flex;
    justify-content: space-around;
  }
  
  .analytics-dropdown-container h3 {
    margin: 0;
  }
  
  .analytics-dropdown-container select {
    font-size: 16px;
  }
  
  .analytics-cards {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    flex: 1;
    margin: 0 10px;
  }
  
  .card h3 {
    margin-bottom: 10px;
  }
  
  .card h1 {
    margin: 0;
    font-size: 36px;
  }
  
  .card:hover{
    transform: scale(1.05);
    transition: ease 0.3s;
  }
  /* For the Analytics Dropdown*/

.analytics-dropdown {
  appearance: none;
  height: 45px;
  background-color: transparent;
  border: 2px solid black;
  float: left;
  border-radius: 8px;
  color: #333;
  cursor: pointer;
  font-size: 16px;
  padding: 10px 16px;
  width: 250px;
  outline: none;
  transition: border-color 0.3s, color 0.3s;
}

.analytics-dropdown:hover,
.analytics-dropdown:focus {
  border-color: #ff6000;
}

.analytics-dropdown option {
  background-color: #fff;
  color: #333;
}

/* Custom arrow */
.analytics-dropdown::after {
  content: '\25BC'; /* Unicode character for downward arrow */
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
}

.date-picker-container{
  display: flex;
  justify-content: space-between;
}

.validation-error{
  position: absolute;
  right: 280px;
  top: 60px;
  font-size: 10px;
  color: red;
}

/* For the DatePicker */
.custom-datepicker {
  font-family: inherit;
  border: 2px solid black;
  border-radius: 8px;
  box-sizing: border-box;
  font-size: 12px;
  color: #333;
}

/* Popper (dropdown) style */
.custom-datepicker-popper {
  border-color: #ff6000;
}

/* Wrapper style */
.custom-datepicker-wrapper {
  width: 150px;
  margin-right: 10px;
  background-color: #fff;
}

/* Calendar dropdown arrow */
.custom-datepicker .react-datepicker__triangle::before {
  border-bottom-color: #ff6000;
}
