.kot-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.kot-select-dropdown {
  width: 150px;
}

.kot-modal-top-area {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.kot-modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  overflow: scroll;
  height: 80%;
  width: 80%;
  max-width: 800px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
}

.kot-modal-header select {
  width: 100px;
}

.kot-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.confirmation-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  border-radius: 12px;
  background-color: white;
  border: 1px solid black;
  z-index: 1000;
}

.confirmation-dialog p {
  margin-bottom: 20px;
}

.confirmation-dialog button {
  margin-right: 60px;
}

.kot-modal-body {
  margin-bottom: 20px;
}

.kot-modal-footer {
  display: flex;
  justify-content: flex-end;
}

.kot-modal-header button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.kot-modal-header button:hover {
  background-color: #0056b3;
}

.table-container table {
  width: 100%;
  border-collapse: collapse;
}

.table-container th,
.table-container td {
  padding: 8px;
  border: 1px solid #ddd;
  text-align: left;
}

.table-container th {
  background-color: #f4f4f4;
}