/* Profile Container */
.profile-container {
  max-width: 500px;
  margin: 20px auto;
  padding: 20px;
  border-radius: 8px;
  background: linear-gradient(135deg, #4685cd78, #e0e6ed);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Profile Details */
.profile-details {
  margin-bottom: 20px;
}

.profile-details label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  color: #333;
}

.profile-details input {
  width: 450px;
  padding: 10px;
  margin-bottom: 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 14px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.profile-details input:focus {
  border-color: #007bff;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25);
  outline: none;
}

.profile-details p {
  margin: 6px 0;
  font-size: 10px;
  color: #555;
}

/* Profile Actions */
.profile-actions {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

.profile-actions button {
  padding: 10px 20px;
  border: none;
  border-radius: 6px;
  background-color: #007bff;
  color: #fff;
  font-size: 0.95rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.profile-actions button:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
}

.profile-actions button:disabled {
  background-color: #d6d6d6;
  color: #888;
  cursor: not-allowed;
  transform: none;
}

/* Modal */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
}

/* Modal Content */
.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  max-width: 400px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  animation: slide-in 0.3s ease-out;
}

/* Modal Actions */
.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 20px;
}

/* Animation for Modal Slide-In */
@keyframes slide-in {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}