.kot-container {
    display: flex;
    flex-direction: column;
}

.kot-content {
    display: flex;
    justify-content: space-between;
}

.kot-table-container {
    width: 60%;
}

.kot-details-container {
    width: 35%;
    border-left: 1px solid #ddd;
    padding-left: 20px;
}

.kot-top-area {
    height: 50px;
    margin: 10px;
    display: flex;
    flex-direction: row-reverse;
}


.kot-canteen-dropdown {
    appearance: none;
    background-color: transparent;
    border: 2px solid black;
    float: left;
    border-radius: 8px;
    color: #333;
    cursor: pointer;
    font-size: 16px;
    padding: 10px 16px;
    width: 200px;
    outline: none;
    transition: border-color 0.3s, color 0.3s;
}

.kot-canteen-dropdown:hover,
.kot-canteen-dropdown:focus {
    border-color: #ff6000;
}

.kot-canteen-dropdown option {
    background-color: #fff;
    color: #333;
}

/* Custom arrow */
.kot-canteen-dropdown::after {
    content: '\25BC';
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    pointer-events: none;
}

.kot-canteen-dropdown {
    position: absolute;
    left: 0;
    margin-left: 10px;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

table th,
table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

table th {
    background-color: #f2f2f2;
}

.even-row {
    background-color: hsl(0, 0%, 85%);
}

.odd-row {
    background-color: #fff;
}

table tr:hover {
    background-color: #00344b2e;
}

.expired-kot {
    background-color: #2b3641;
    color: white;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
}

.active-kot {
    background-color: #ff6000;
    color: white;
    border: none;
    font-size: 16px;
    padding: 10px 15px;
    cursor: pointer;
}

.expired-kot:hover {
    background-color: #45403b;
}
.active-kot:hover {
    background-color: #8d4b11;
}

/* Pagination styles */
.pagination {
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 0;
}

.pagination li {
    margin: 0 5px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.pagination li.active {
    background-color: #007bff;
    color: #fff;
    border-radius: 50px;
}

.pagination li:hover:not(.active) {
    background-color: #0000002a;
    border-radius: 50px;
}

.pagination a {
    display: block;
    padding: 8px 16px;
    text-decoration: none;
    color: #333;
}

.pagination a:hover {
    color: #ff6000;
}


.kot-top-area {
    margin-top: -50px;
    display: relative;
    align-items: center;
    padding: 30px;
    position: relative;
    background-color: rgba(0, 204, 255, 0.229);
    border-radius: 15px;
}