/* table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }

  
  th {
    font-size: 14.5px;
    background-color: #f2f2f2;
  }

  td {
    font-family: 'Open Sans', sans-serif;
    font-size: 13.5px;
  }
   */

table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 15px;
  padding: 10px;
}

thead {
  display: table-header-group;
  /* Fix for modern styling */
}

tbody {
  display: table-row-group;
  /* Fix for modern styling */
}

th,
td {
  background-color: #ffffff; /* Removing this */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* and this will almost bring back the original style */
  border: none;
  padding: 16px;
  text-align: left;
}

th {
  font-size: 15px;
  font-weight: 600;
  color: #333;
  background-color: #f7f9fc;
}

td {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #555;
}

tr {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

tr:hover {
  transform: scale(1.01);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

button {
  color: black;
  font-family: 'Poppins';
  border: none;
  cursor: pointer;
  margin: 5px;
  padding: 10px;
  border-radius: 20px;
  background-color: rgba(0, 66, 99, 0.251);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  /* Added drop shadow */
  transition: transform 0.2s, box-shadow 0.2s;
  /* Smooth transition */
}

button:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
  /* Enhanced shadow on hover */
}

.add-button {
  background-color: purple;
  color: white;
  font-family: 'Poppins';
  float: right;
}

/* CSS for the add popup overlay */
.add-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  /* Ensure the popup appears above other content */
}

/* CSS for the add popup container */
.add-popup {
  background: white;
  padding: 40px;
  border-radius: 8px;
  width: 60%;
  height: 80%;
  max-width: 600px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  /* Make the content inside the popup scrollable */
}

/* Additional CSS for form inside the popup */
.add-popup form {
  display: flex;
  flex-direction: column;
}

.add-popup label {
  margin-top: 10px;
}

.add-popup input[type="text"],
.add-popup input[type="date"],
.add-popup input[type="file"] {
  margin-top: 5px;
  margin-bottom: 15px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.add-popup .toggle-switch {
  display: flex;
  gap: 10px;
}

.add-popup .toggle-switch label {
  margin: 0;
  padding: 0 5px;
}

.food-Image {
  margin-left: 15px;
  margin-right: 15px;
  width: 80px;
  height: 80px;
  object-fit: cover;
}

/* For the Canteen Dropdown*/

.canteen-dropdown {
  appearance: none;
  background-color: transparent;
  border: 2px solid black;
  float: left;
  border-radius: 8px;
  color: #333;
  cursor: pointer;
  font-size: 16px;
  padding: 10px 16px;
  width: 200px;
  outline: none;
  transition: border-color 0.3s, color 0.3s;
}

.canteen-dropdown:hover,
.canteen-dropdown:focus {
  border-color: #ff6000;
}

.canteen-dropdown option {
  background-color: #fff;
  color: #333;
}

/* Custom arrow */
.canteen-dropdown::after {
  content: '\25BC';
  /* Unicode character for downward arrow */
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
}

.delete-button:hover {
  background-color: red;
}

/* Tab bar */
.top-area {
  margin-top: -50px;
  display: relative;
  align-items: center;
  padding: 30px;
  position: relative;
}

.search-box {
  padding: 20px;
}

.canteen-dropdown {
  position: absolute;
  left: 0;
  margin-left: 10px;
}

.tab-bar {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tab-button {
  margin: 0 10px;
  padding: 10px 20px;
  border: none;
  background-color: #f0f0f0;
  cursor: pointer;
  transition: background-color 0.3s;
}

.tab-button.active {
  background-color: #007bff;
  color: white;
}

.tab-button:hover {
  color: #ff6000;
  background-color: #00000030;
}